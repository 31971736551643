import React from 'react'
import { OutboundLink } from "gatsby-plugin-google-gtag"

const ContactInfo = () => {
    return (
        <div className="contact-info-area pt-100 pb-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="contact-info-box">
                            <div className="back-icon">
                                <i className='bx bxl-whatsapp'></i>
                            </div>
                            <div className="icon">
                                <i className='bx bxl-whatsapp'></i>
                            </div>
                            <h3>Message Us</h3>
                            <p><OutboundLink href="https://api.whatsapp.com/send?l=en&phone=447538706864" rel="noreferrer noopener" target="_blank">+44 (0) 7538 706864</OutboundLink></p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="contact-info-box">
                            <div className="back-icon">
                                <i className='bx bx-mail-send'></i>
                            </div>
                            <div className="icon">
                                <i className='bx bx-mail-send'></i>
                            </div>
                            <h3>E-mail</h3>
                            <p><OutboundLink href="mailto:hello@connectiongoal.com" rel="noreferrer noopener" target="_blank">hello@connectiongoal.com</OutboundLink></p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                        <div className="contact-info-box">
                            <div className="back-icon">
                                <i className='bx bx-time-five'></i>
                            </div>
                            <div className="icon">
                                <i className='bx bx-time-five'></i>
                            </div>
                            <h3>Hours of Operation</h3>
                            <p>Monday - Friday: 09:00 - 17:00</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactInfo