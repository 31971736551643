import React from "react"
import starIcon from "../../assets/images/star-icon.png"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from 'gatsby'

const ContactThanks = () => {
  return (
    <section className="contact-area pb-100">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <img src={starIcon} alt="contact" />
            Get in Touch
          </span>
          <h2>Ready to Get Connected?</h2>
          <p>
            Your email address will not be published. Required fields are marked
            *
          </p>
        </div>

        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="contact-image">
              <StaticImage
                src="../../assets/images/contact/contact.jpg"
                placeholder="blurred"
                alt="Contact Us"
              />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="contact-form">
            <div className="contact-form">
              <h2>Thanks for contacting Connection Goal!</h2>
              <br/>
              <br/>
              <p>We’ll reach out to you about your request and share content and updates about Connection Goal products. <br />
              We use your information according to our <Link to="/privacy-policy">privacy policy</Link>.<br /> 
              You can update your preferences at any time.</p>
            </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactThanks
