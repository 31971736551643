import React from 'react'
import Layout from "../components/App/Layout"
import PageBannerBackground from "../components/Common/PageBannerBackground"
import ContactInfo from '../components/Contact/ContactInfo'
import ContactThanks from '../components/Contact/ContactThanks'

const ContactReponse = () => {
    return (
        <Layout page="Contact Us">
            <PageBannerBackground
                pageTitle="CONTACT US"
                crumbs={[{page: "Home", url: "/"}]}   
                bgFileName="contact/contact-banner.jpg"
            />
            <ContactInfo />
            <ContactThanks />
        </Layout>
    );
}

export default ContactReponse